import { Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';

export default function FormSwitch( { name, ...props }: { name: string } & SwitchProps ) {
	
	const [ { value }, , { setValue } ] = useField( name );
	
	return (
		<Switch
			checked={value ?? false}
			{...props}
			onChange={( e, checked: boolean ) => {
				setValue( checked );
				props.onChange?.( e, checked );
			}}
		/>
	);
}

