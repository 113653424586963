import type { SelectProps } from '@mui/material';
import { Box, FormControl, FormHelperText, Select } from '@mui/material';
import { useField } from 'formik';
import type { ReactNode } from 'react';
import TextFieldInputLabel from '../inputLabel';

export default function FormSelect( {
	name,
	label,
	tooltip,
	helperText,
	...props
}: {
	name: string,
	tooltip?: ReactNode,
	helperText?: string
} & SelectProps ) {
	const [ field, { touched, error } ] = useField( name );
	
	return (
		<Box>
			<TextFieldInputLabel
				label={label}
				tooltip={tooltip}
				isError={touched && Boolean( error )}
			/>
			<FormControl
				size='small'
				fullWidth={props.fullWidth}
				error={Boolean( touched && error )}>
				
				<Select
					{...field}
					value={String( typeof field.value === 'number' ? Math.abs( field.value ) : field.value ?? '' )}
					{...props}
				/>
				
				{touched && error || helperText ? (
					<FormHelperText error={Boolean( touched && error )}>
						{touched && error ? error : helperText}
					</FormHelperText>
				) : null}
			</FormControl>
		</Box>
	);
}
