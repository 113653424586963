import CreditCardBrandImage from '@/components/creditCardBrandImage';
import EnhancedDisplay from '@/components/enhancedDisplay';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import NewCardModal from '@/modals/newCardModal';
import { UserClientFormType } from '@/pages/settings/clientInfo';
import { useModal } from '@/providers/modal';
import { ListItemText, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { atom, useAtom } from 'jotai/index';
import { startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';

export type SavedCardType = {
	id: string,
	last4: string,
	first6: string,
	cardType: string,
	expirationDate: string,
	token: string
};

export function useFetchCloverTips( gatewayId: string ): ( [ boolean, any[] ] ) {
	
	const [ tips, setTips ] = useState( [] );
	const [ loading, setLoading ] = useState( true );
	
	useAsyncEffect( async () => {
		try {
			if ( gatewayId ) {
				const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/getTips`, {
					id: gatewayId,
				} );
				
				if ( data.data ) {
					const sortedCloverTips = data.data?.filter( ( tip ) => tip.isEnabled )
						?.sort( ( a, b ) => a.percentage - b.percentage );
					setTips( sortedCloverTips );
					setLoading( false );
					
				}
			} else {
				setLoading( false );
			}
		} catch {
			setLoading( false );
			
		}
		
	}, [ gatewayId ] );
	
	if ( !gatewayId ) return [ loading, tips ];
	
	return [ loading, tips || [] ];
};

export function useFetchCloverCards( clientId: string,
	externalId?: string ): ( [ boolean, any[], () => Promise<void> ] ) {
	
	const [ cards, setCards ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	
	const fetchCloverCards = async () => {
		try {
			if ( clientId && externalId ) {
				setLoading( true );
				const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getCard`, {
					id: clientId,
				} );
				if ( data?.cards?.elements ) {
					setCards( data.cards.elements.filter( ( card ) => card?.token && card?.token?.startsWith( 'clv' ) ) );
					setLoading( false );
					
				}
			} else {
				setLoading( false );
			}
		} catch {
			setCards( [] );
			setLoading( false );
			
		}
	};
	
	useAsyncEffect( async () => {
		await fetchCloverCards();
	}, [ clientId ] );
	
	if ( !externalId ) return [ loading, cards, fetchCloverCards ];
	
	return [ loading, cards || [], fetchCloverCards ];
};

export const surchargeFeeAtom = atom<number | undefined>( 0 );

export function useFetchCloverSurcharge( gatewayId: string | null ): ( [ boolean, number, () => Promise<void> ] ) {
	const [ surchargePercent, setSurchargePercent ] = useAtom( surchargeFeeAtom );
	const [ loading, setLoading ] = useState( false );
	
	const fetchCloverSurcharge = async () => {
		try {
			if ( gatewayId ) {
				setLoading( true );
				const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getSurcharge`, {
					id: gatewayId,
				} );
				if ( data ) {
					const hasSurcharging = data?.surcharging?.supported;
					const rate = data?.surcharging?.rate;
					setSurchargePercent( hasSurcharging && rate > 0 ? rate * 100 : 0 );
					setLoading( false );
				}
			} else {
				setLoading( false );
			}
		} catch {
			setSurchargePercent( 0 );
			setLoading( false );
			
		}
	};
	
	useAsyncEffect( async () => {
		await fetchCloverSurcharge();
	}, [ gatewayId ] );
	
	if ( !gatewayId ) return [ loading, surchargePercent, fetchCloverSurcharge ];
	
	return [ loading, surchargePercent, fetchCloverSurcharge ];
};

export const fetchCloverSurcharge = async ( gatewayId: string,
	setSurchargePercentage: ( surcharge: number ) => void ) => {
	try {
		const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getSurcharge`, {
			id: gatewayId,
		} );
		if ( data ) {
			const hasSurcharging = data?.surcharging?.supported;
			const rate = data?.surcharging?.rate;
			setSurchargePercentage( hasSurcharging && rate > 0 ? rate * 100 : 0 );
		}
		
	} catch {
		setSurchargePercentage( 0 );
	}
	
};

export const fetchCards = async ( clientId: string, setCards ) => {
	try {
		const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getCard`, {
			id: clientId,
		} );
		if ( data?.cards?.elements ) {
			setCards( data.cards.elements.filter( ( card ) => card?.token && card?.token?.startsWith( 'clv' ) ) );
		}
	} catch {
		setCards( [] );
	}
	
};
export default function Cards() {
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const confirmDialog = useConfirmDialog();
	const [ cards, setCards ] = useState( [] );
	const { t } = useTranslation();
	const formik = useFormikContext<UserClientFormType>();
	
	const selectedClient = formik.values.selectedClient;
	
	useAsyncEffect( async () => {
		try {
			if ( !selectedClient?.clientexternalid ) return;
			await fetchCards( selectedClient.clientid, setCards );
		} catch {
		}
		
	}, [ selectedClient ] );
	
	if ( !selectedClient?.clientid ) return null;
	
	return (
		<Paper sx={{ p: 2 }}>
			<EnhancedDisplay<SavedCardType>
				pageSectionProps={{
					primary      : t( 'common:saved-card' ),
					listItemProps: { sx: { px: 0, pb: .5, mb: { xs: 0, sm: 1 } } },
					actions      : [ selectedClient?.clientexternalid && {
						name   : t( 'common:add-card' ),
						onClick: () => {
							const gatewayExternalKey = JSON.parse( atob( decodeURIComponent( selectedClient.gatewayexternalkey as string ) ) );
							
							const clientInfo: any = {
								id     : selectedClient.clientid,
								name   : selectedClient.clientname,
								contact: selectedClient.clientcontact,
								email  : selectedClient.clientemail,
								gateway: {
									id                : selectedClient.gatewayid,
									externalKey       : gatewayExternalKey,
									cloverApiAccessKey: selectedClient.gatewaycloveraccesskey,
								},
							};
							
							showModal( NewCardModal, { maxWidth: 'sm' }, {
								client  : clientInfo,
								setCards: setCards,
							} );
						},
					} ],
				}}
				data={cards}
				listProps={{
					dense    : true,
					renderRow: ( card ) => (
						<Fragment>
							<ListItemText>{card.first6}</ListItemText>
							<ListItemText>{card.last4}</ListItemText>
							<Typography>{startCase( toLower( card.cardType ) )}</Typography>
							<Typography>{card?.expirationDate
								? `${card.expirationDate.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
								: '-'}
							</Typography>
						</Fragment>
					),
				}}
				tableProps={{
					hover    : false,
					size     : 'small',
					headers  : [ `${t( 'common:card' )}#`,
					             t( 'common:type' ),
					             t( 'common:expiration' ),
					             t( 'common:action' ) ],
					columns  : ( card ) => [
						<Stack key='number' direction='row' alignItems='center' spacing={1}>
							<Typography>
								{card.first6 + ' ****** ' + card.last4}
							</Typography>
							<CreditCardBrandImage key='brand' brand={card.cardType}/>
						</Stack>,
						startCase( toLower( card.cardType ) ),
						`${card?.expirationDate
							? `${card.expirationDate.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
							: '-'}`,
						<AsyncLoadingButton
							key={card.id}
							color='error'
							variant='text'
							onClick={async () => {
								const value = await confirmDialog( {
									title  : t( 'common:delete' ),
									message: t( 'common:delete-confirmation' ),
								} );
								if ( !value ) return;
								try {
									await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/deleteCard`, {
										id      : selectedClient.clientid,
										cardId  : card.id,
										sourceId: card.token,
									} );
								} catch {
								}
								
								enqueueSnackbar( t( 'common:card-remove-success' ), { variant: 'success' } );
								await fetchCards( selectedClient.clientid, setCards );
								
							}}>
							Remove
						</AsyncLoadingButton>,
					],
					cellProps: [
						{ align: 'left' },
						{ align: 'left' },
						{ align: 'left' },
						{ align: 'right' },
					],
				}}
			/>
		</Paper>
	);
}
