import type { Gateway, Payment } from '@/types/schema';
import type { MutableRefObject } from 'react';
import { useRef } from 'react';
import CloverCard from './card';

export default function CloverPaymentDetails( {
	gateway,
	method,
	createPayment,
	onSubmit,
}: {
	gateway: Gateway,
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>
} ) {
	const clover = useRef<any>();
	
	switch ( method ) {
		case 'card':
			onSubmit.current = async () => {
				if ( !clover.current ) return;
				const result = await clover.current.createToken();
				if ( result.errors ) throw new Error( Object.values( result.errors ).join( ', ' ) );
				const data = await createPayment( { cardId: result.token } );
				return data?.payment;
			};
			
			return <CloverCard gateway={gateway} clover={clover}/>;
	}
	
	return null;
}
