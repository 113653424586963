import { safeConvertToZonedTime } from '@/helpers/safeFormat';
import { FormHelperText } from '@mui/material';
import {
	DatePicker,
	type DatePickerProps,
	DateTimePicker,
	DateTimePickerProps,
	TimePicker,
	type TimePickerProps,
} from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import { Fragment } from 'react';
import DateTextFieldSlot, { DateTextFieldSlotProps } from '../../muiDatePickerFields/dateTextFieldSlot';

export default function FormDatePicker( {
	name,
	type,
	textFieldProps,
	disablePast = false,
	...props
}: {
	name: string,
	label?: string,
	type?: 'date' | 'time' | 'datetime-local' | 'month',
	textFieldProps?: DateTextFieldSlotProps,
	disablePast?: boolean
} & Partial<DatePickerProps<any> & DateTimePickerProps<any> & TimePickerProps<any>> ) {
	const [ , { touched, error, value = null }, { setValue } ] = useField( name );
	
	const picker = ( () => {
		switch ( type ) {
			case 'date':
			default:
				return (
					<DatePicker<Date>
						value={safeConvertToZonedTime( value ) || null}
						slots={{ textField: DateTextFieldSlot }}
						onChange={( date ) => setValue( date )}
						{...props}
						slotProps={{ textField: textFieldProps, ...props?.slotProps }}
					/>
				);
			case 'time':
				return (
					<TimePicker<Date>
						value={value}
						slots={{ textField: DateTextFieldSlot }}
						ampm={false}
						onChange={( date ) => setValue( date )}
						{...props}
						slotProps={{ textField: textFieldProps, ...props?.slotProps }}
					/>
				);
			case 'datetime-local':
				return (
					<DateTimePicker<Date>
						disablePast={disablePast}
						value={value}
						slots={{ textField: DateTextFieldSlot }}
						timeSteps={{ minutes: 1 }}
						ampm={false}
						closeOnSelect={false}
						{...props}
						slotProps={{ textField: { label: props.label, ...textFieldProps }, ...props?.slotProps }}
						onChange={( date, context ) => {
							setValue( date );
							if ( typeof props.onChange === 'function' ) {
								props.onChange( date, context );
							}
						}}
					/>
				);
		}
	} )();
	
	return (
		<Fragment>
			{picker}
			{touched && Boolean( error ) && <FormHelperText error>{error}</FormHelperText>}
		</Fragment>
	);
}
