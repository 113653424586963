import { TiersMeta } from '@/baseline/subscription/tiers';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Permission, SubscriptionTier, TierFeature, TierFeatures } from '@/types/tiers';
import { isAfter } from 'date-fns';
import { getSubscription } from './getSubscription';

export const useTierPermissions = (): TierFeatures | null => {
	const { company, loading } = useCompany();
	if ( loading ) return null;
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	const activeTrialSub = company?.subscriptions?.find( ( sub ) =>
		sub.status === 'ACTIVE' && sub.tier.name === SubscriptionTier.TRIAL && isAfter( new Date( sub.endDate ), new Date() ) );
	// Trial tier feature is the highest
	if ( activeTrialSub ) return TiersMeta.Enterprise.features;
	
	if ( !subscription ) return null;
	
	// TODO: Remove this check after warning period
	if ( subscription?.endDate && isAfter( new Date( subscription.endDate ), new Date() ) ) {
		return TiersMeta.Enterprise.features;
	}
	
	return TiersMeta[ subscription.tier.name ]?.features;
};

/**
 * `useSubscription` Hook
 * This hook is used to check the validity of the user's subscription and
 * to determine access to various features based on the subscription tier.
 */
export default function useTierPermission( type: Permission ): boolean {
	const { user } = useUserInfo();
	const limiting = useTierPermissions();
	if ( !limiting ) return false;
	
	switch ( type ) {
		case 'TEAM_MEMBER':
			return ( user?.staffs?.length || 0 ) <= limiting.teamMember;
		
		default:
			const key = TierFeature[ type ];
			return limiting[ key ];
	}
}
