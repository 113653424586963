import { getPlanForPermission } from '@/baseline/subscription/tiers';
import PageLinkComponent from '@/components/page/linkComponent';
import CustomTooltip from '@/components/popups/customTooltip';
import { planColors } from '@/pages/dashboard/settings/subscription/currentSubscription';
import { Permission } from '@/types/tiers';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash-es';
import React from 'react';

export default function UpgradeIcon( {
	removeText = true,
	iconSize = 14,
	variant,
	permission,
	articleRoute = 's',
}: {
	removeText?: boolean,
	iconSize?: number,
	variant?: 'default' | 'inverted',
	permission?: Permission,
	articleRoute?: string
} ) {
	
	const scaleFactor = iconSize / 14;
	const adjustedPadding = 0.5 * scaleFactor;
	const plan = permission ? getPlanForPermission( permission ) : '';
	return (
		<CustomTooltip
			toolTipChildren={permission && (
				<Stack spacing={2} sx={{ p: 1 }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box
							sx={{
								display       : 'flex',
								alignItems    : 'center',
								justifyContent: 'center',
								p             : adjustedPadding,
								border        : 1,
								borderRadius  : '100%',
								borderColor   : 'divider',
								bgcolor       : 'alpha.primary',
							}}>
							<LockRoundedIcon
								color='primary'
								style={{ fontSize: iconSize, color: 'primary.main' }}
							/>
						</Box>
					</Box>
					<Stack direction='row' alignItems='center' spacing={.5}>
						<Typography>
							Available under the
						</Typography>
						<Chip
							label={capitalize( plan )}
							variant='alpha'
							sx={{ ...planColors[ plan ], color: '#ffffff' }}
						/>
						<Typography sx={{ color: 'text.secondary' }}>
							plan and up.
						</Typography>
					</Stack>
					{articleRoute && (
						<Button
							variant='contained'
							color='inverted'
							component={PageLinkComponent}
							href={articleRoute}>
							Learn More
						</Button>
					)}
				</Stack>
			)}>
			<Box
				sx={{
					display   : 'flex',
					alignItems: 'center',
				}}>
				<Box
					sx={{
						display       : 'flex',
						alignItems    : 'center',
						justifyContent: 'center',
						p             : adjustedPadding,
						border        : 1,
						borderRadius  : '100%',
						borderColor   : 'divider',
						bgcolor       : variant === 'inverted' ? 'gray' : 'alpha.primary',
					}}>
					<LockRoundedIcon
						color='primary'
						style={{ fontSize: iconSize, color: variant === 'inverted' ? '#ffffff' : 'primary.main' }}
					/>
				</Box>
				{!removeText && (
					<Typography
						sx={{
							color     : variant === 'inverted' ? '#ffffff' : 'primary.main',
							fontWeight: 500,
							pl        : scaleFactor,
						}}>
						Upgrade
					</Typography>
				)}
			</Box>
		</CustomTooltip>
	);
}
