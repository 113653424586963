import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import FormattedTextField from '@/components/formattedTextField';
import { queryGraphQL } from '@/data/apollo';
import { IsValidCompanyPinCode } from '@/data/company.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { QueryIsValidCompanyPinCodeArgs } from '@/types/schema';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';

type DisablePinCodeFormType = { pinCode: string };

export default function RequirePinCodeModal( {
	onSubmit,
	disableCancel,
}: {
	onSubmit: ( approvedPinCode: boolean ) => void,
	disableCancel?: boolean
} ) {
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	
	const company = staff.company;
	
	return (
		<Form<DisablePinCodeFormType>
			initialValues={{ pinCode: '' }}
			onSubmit={async ( values ) => {
				const { isValidCompanyPinCode } = await queryGraphQL<QueryIsValidCompanyPinCodeArgs>( {
					query    : IsValidCompanyPinCode,
					variables: { pinCode: values.pinCode, companyId: company.id },
				} );
				if ( !isValidCompanyPinCode ) {
					onSubmit( false );
					throw 'Pin code does not match.';
				}
				enqueueSnackbar( 'Pin code approved successfully', { variant: 'success' } );
				onSubmit( true );
				closeModal();
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title='Pin Code Required'
					saveButtonText='Submit'
					cancelButtonProps={{ disabled: disableCancel }}
					saveButtonProps={{ disabled: !formik.values.pinCode?.length }}>
					<Stack spacing={1}>
						<FormattedTextField
							fullWidth
							name='pinCode'
							label='Pin Code'
							placeholder='Enter Pin Code'
							value={formik.values.pinCode}
							InputProps={{ inputProps: { min: 4, max: 6 }, type: 'password' }}
							onChange={async ( e ) => {
								if ( !isEmpty( e.target.value ) ) {
									const pin = e.target.value.replace( /\s/g, '' );
									formik.setFieldValue( 'pinCode', pin );
								}
							}}
							onFocus={( event ) => event.target.select()}
						/>
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
